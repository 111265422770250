import { Component } from '@angular/core';
import { AbstractInjectBaseComponent } from '../../../../../../../core/abstracts/abstract-inject-base.component';
import { DialogService } from '../../../../../../shared/providers/dialog.service';
import { OwInject } from '../../../../../../../core/decorators/ow-inject.decorator';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { OwDate } from '../../../../../../../core/utility/ow-date.class';
import { STOCK_VIEW } from '../../../../shared-ui/mobile/consts/stock-view.const';
import { Task } from '../../../../san-business/interfaces/custom/task.interface';
import { CardTaskDetailsCCComponent } from '../card-task-details-cc/card-task-details-cc.component';

@Component({
  selector: 'app-choose-task-cc',
  templateUrl: './choose-task-cc.component.html',
})
export class ChooseTaskCCComponent extends AbstractInjectBaseComponent {
  @OwInject(DialogService) dialogService: DialogService;
  @OwInject(MAT_DIALOG_DATA) data: {
    tasks: Task[],
    allTasks: Task[],
    currentIndex: number,
    owDate: OwDate,
    position: number,
  };
  STOCK_VIEW = STOCK_VIEW;

  openCardTaskDetails(task: Task) {
    this.dialogService.open(CardTaskDetailsCCComponent, {
      data: {
        task: task,
        tasks: this.data.allTasks,
        isChooseableView: true,
        currentIndex: this.data.currentIndex,
        owDate: this.data.owDate,
        position: this.data.position,
      }
    });
  }
}
